import React, { useEffect, useState } from "react";
import base64url from "base64url";
import cryptoBrowserify from "crypto-browserify";
import App from './Front/App'

const COGNITO_FEDERATED_URL_SIGN_UP = `https://${process.env.REACT_APP_COGNITO_IDENTITY_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com/oauth2/authorize?redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI_LOGIN}&response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=${process.env.REACT_APP_COGNITO_SCOPE}`;
const COGNITO_FEDERATED_URL_SIGN_OUT = `https://${process.env.REACT_APP_COGNITO_IDENTITY_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com/logout?logout_uri=${process.env.REACT_APP_COGNITO_REDIRECT_URI_LOGOUT}&response_type=code&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&scope=${process.env.REACT_APP_COGNITO_SCOPE}`;
const COGNITO_FEDERATED_URL_TOKEN = `https://${process.env.REACT_APP_COGNITO_IDENTITY_DOMAIN}.auth.${process.env.REACT_APP_COGNITO_REGION}.amazoncognito.com/oauth2/token`;

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

const sha256 = (buffer) => {
  return cryptoBrowserify.createHash("sha256").update(buffer).digest();
};

const sendEvent = (worker, data) =>
  new Promise((res, rej) => {
    const channel = new MessageChannel();

    channel.port1.onmessage = ({ data }) => {
      channel.port1.close();

      if (data.error) {
        rej(data.error);
      } else {
        res(data.result);
      }
    };

    worker.active.postMessage([data], [channel.port2]);
  });

export default function Login({ children }) {
  const nonce = window.localStorage.getItem("nonce");
  const codeVerifier = window.localStorage.getItem("codeVerifier");
  const url = new URL(window.location.href.replace(/#/g, "?"));
  const [worker, setWorker] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [code, _] = useState(url.searchParams.get("code"));
  const [state, __] = useState(url.searchParams.get("state"));
  const [challenge, setChallenge] = useState(null);
  const [userInformation, setUserInformation] = useState('');
  const [credential, setCredential] = useState('');
  const federatedURL = `${COGNITO_FEDERATED_URL_SIGN_UP}&state=${nonce}&code_challenge_method=${process.env.REACT_APP_COGNITO_CODE_CHALLENGE_METHOD}&code_challenge=${challenge}`;
  
  useEffect(() => {
    if (!codeVerifier) {
      window.localStorage.setItem(
        "codeVerifier",
        base64url(cryptoBrowserify.randomBytes(32))
      );
    }

    if (!nonce) {
      window.localStorage.setItem(
        "nonce",
        base64url(cryptoBrowserify.randomBytes(16))
      );
    }

    setChallenge(
      base64url(sha256(window.localStorage.getItem("codeVerifier")))
    );

    navigator.serviceWorker.ready.then((worker) => {
      setWorker(worker);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (worker)
        sendEvent(worker, {
          type: "HAVE_VALID_CREDENTIALS",
        }).then((result) => {
          if (result) {
            let token = result.id_token;
            let tokens = token.split('.');
            let informacion = tokens[1];

            setRedirect(true);
            setCredential(result.id_token);
            setUserInformation(JSON.parse(b64DecodeUnicode(informacion)));
            clearInterval(interval);
          } else {
            window.location.href = federatedURL;
          }
        });
    }, 1000);

    return () => clearInterval(interval);
  }, [worker]);

  useEffect(() => {
    if (code && worker && state && state === nonce) {
      window.localStorage.removeItem("nonce");
      window.localStorage.removeItem("codeVerifier");

      sendEvent(worker, {
        type: "SET_AUTH_DATA",
        payload: {
          code,
          codeVerifier,
          clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
          redirectUri: process.env.REACT_APP_COGNITO_REDIRECT_URI_LOGIN,
          tokenUrl: COGNITO_FEDERATED_URL_TOKEN,
          singOutUrl: COGNITO_FEDERATED_URL_SIGN_OUT,
        },
      }).catch((error) => {
        console.error(error);
      });
    }
  }, [code, worker, state]);

  if(redirect) {
    return (
      <App user={userInformation} credentials={credential} logout={COGNITO_FEDERATED_URL_SIGN_OUT}/>
    );
  }
}